import * as api from "../api/loginHttp";

export default (app) => {
    // app.globals.$test = () => {
    //     console.log('hello world')
    // }
    app.config.globalProperties.$getPageList = async (params) => {
        const data = await api.archives(params);
        return data
    }

    app.config.globalProperties.$getEvaluationList = async (params) => {
        const data = await api.evaluation(params);
        return data
    }


    app.config.globalProperties.$getTimes = (params) => {
        var date = new Date(params);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        const time = y + "-" + m + "-" + d;
        return time;
    }
	
	app.config.globalProperties.$filters = {
	  currencyUSD(value, num) {
	    const nums = num || '5';// 设置限定字数,默认为5
	    if (!value) return '';
	    if (value.length > nums) {
	      return value.slice(0, nums) + '...';
	    }
	    return value;
	  }
	}


}
