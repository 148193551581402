import { createStore  } from 'vuex'

const store = new createStore({
    // 初始化的数据
    state: {
        token: '',
        userId: '',
        username: '',
        adminname: '',
        adminid: '',
    },
    // 改变state里面的值得方法
    mutations: {
        saveToken(state, data) {
            state.token = data;
            window.localStorage.setItem("Token", data);
        },
        saveUserId(state, data) {
            state.userId = data;
            window.localStorage.setItem("UserId", data);
        },
        saveUsername(state, data) {
            state.username = data;
            window.localStorage.setItem("username", data);
        },
        saveAdminName(state, data) {
            state.adminname = data;
            window.localStorage.setItem("adminname", data);
        },
        saveAdminId(state, data) {
            state.adminid = data;
            window.localStorage.setItem("adminid", data);
        },

    }
});
// 输出模块
export default store;
