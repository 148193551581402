import { createRouter, createWebHashHistory } from 'vue-router'
import Index from './routes/index'
import Ngf from './routes/ngf'

// 3. Create the routes instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes: [...Index, ...Ngf], // short for `routes: routes`
})

// 5. Create and mount the root instance.

// Make sure to _use_ the routes instance to make the
// whole app routes-aware.

export default (app) => {
    app.use(router)
}
