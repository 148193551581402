<template>
	<div class="body-bg">
		<el-affix >
			<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;margin-left: 10px;">‹</span><div style="margin-right: 20px;">{{ title }}</div></div>
		</el-affix>
		<div id="search">
		 <el-form :inline="true" class="demo-form-inline" @submit.prevent='Search' >
			<el-form-item class="elInput">
			  <el-input v-model="keyword" placeholder="搜索" :prefix-icon="Search" @keyup.enter="onDefault(0, 0, 1)"></el-input>
			</el-form-item>
		  </el-form>
		</div>
		
		<div id="Treatcontent">
			<el-row  v-infinite-scroll="load" infinite-scroll-distance="10" infinite-scroll-immediate="true" style="overflow:auto">

				<el-col :span="24" id='clear' v-for="item in tableData" :key="item.index" v-show="iconDef == 2">
				  <div class="contentTitle">
				  <span class="contentInfo" style="font-weight: 900;">{{ item.address}}</span>
				  </div>
				  <div class="contentTitle">
				  <span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_address.png'): require('@/assets/img/equipment_address.png')"
				  alt="" style="width: 14px;height: 19px; margin-top: 10%;"></span>
				  <span class="contentInfo">{{item.contents}}</span>
				  </div>
				  <div class="contentBrief" @click="dialPhoneNumber(item.mobile)">
				  	<span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_phone.png'): require('@/assets/img/equipment_phone.png')"
				  	 alt="" style="width: 14px;height: 19px;margin-top: 10%;"></span>
				  	<span class="contentInfo">联系方式：{{ $filters.currencyUSD(item.mobile, 20) }}</span>
				  </div>
				  <!-- <hr> -->
				</el-col>

				 <el-col :span="24" id='clear' v-for="item in tableData" :key="item.index" v-show="iconDef == 1">
				  <div class="contentTitle">
				  <span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_address.png'): require('@/assets/img/equipment_address.png')"
				  alt="" style="width: 14px;height: 19px; margin-top: 50%;"></span>
				  <span class="contentInfo" style="margin-top: 2%;">{{item.address}}</span>
				  </div>
				  <!-- <div class="contentBrief" @click="dialPhoneNumber(item.mobile)">
				  	<span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_phone.png'): require('@/assets/img/equipment_phone.png')"
				  	 alt="" style="width: 14px;height: 19px;margin-top: 10%;"></span>
				  	<span class="contentInfo">联系方式：{{ $filters.currencyUSD(item.mobile, 20) }}</span>
				  </div> -->
				  <!-- <hr> -->
				</el-col>

			  <!-- <el-col :span="24" id='clear' v-for="item in tableData" :key="item.index">
				  <div class="contentTitle">
				  <span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_address.png'): require('@/assets/img/equipment_address.png')"
				  alt="" style="width: 14px;height: 19px; margin-top: 20%;"></span>
				  <span class="contentInfo">相关机构名称：{{ $filters.currencyUSD(item.address, 20) }}</span>
				  </div>
				  <div class="contentBrief" @click="dialPhoneNumber(item.mobile)">
				  	<span class="contentIcon"><img :src="iconDef == 1 ? require('@/assets/img/medical_phone.png'): require('@/assets/img/equipment_phone.png')"
				  	 alt="" style="width: 14px;height: 19px;margin-top: 10%;"></span>
				  	<span class="contentInfo">地址联系方式：{{ $filters.currencyUSD(item.mobile, 20) }}</span>
				  </div>
				</el-col> -->
			</el-row>
		
			<div class="loading" v-if="loading">
			  <span id="load-text">{{loadText}}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage, ElNotification} from "element-plus";	
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
	const formInline  = reactive({
		user: '',
		region: '',
	})
	const beforeCreate = async () => {
		document.querySelector('body').setAttribute('style', 'background-color:#EFEFEF;')
	}

	const title = ref()
	const keyword = ref()
	const contents = ref({})
	const loadText = ref('正在加载...')
	const tableData = ref([])
	const loading = ref(false)
	const pages = ref(0);
	const limit = ref(20);
	const totalPages = ref();
	const iconDef = ref();
	
	const fetchData = async () => {
		cateid.value = route.params.cateid
		if(cateid.value == 5) {
			iconDef.value = 1;
		} else {
			iconDef.value = 2;
		}
		http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
			if(res.data.code == 200) {
				title.value = res.data.data
			} else {
				ElNotification({
				    title: 'Error',
				    message: '获取数据错误',
				    type: 'error',
				  })
				return false;
			}
		})
	}
	const onHome = async () =>{
		await router.push({'path':'/big/Home/'})
	}
	
	const onDefault = async (isReset, addTo = 0, Botton = 0) => {

	  if(Botton == 1 ){
		pages.value = 0;
		tableData.value =  [];
	  }
	
	  let params = {
	    'cateid': cateid.value,
	    'pages': pages.value,
	    'limit': limit.value,
	  }
	
	  params.pages = pages.value
	
	  if (keyword.value != "") {
	    params.keyword = keyword.value
	  }
	  const data = await api.getNewList(params);
	  let len = data.data.data.contents.length
	  if (data && len == 0 && !len) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else if (data && len < 20) {
	    loadText.value = '暂无更多数据'
		loading.value = true;
	  } else {
	    loading.value = false
	  }
	  if(len > 0) {
	  		pages.value = pages.value + 20; //页数+10
	  }
	  tableData.value = tableData.value.concat(data.data.data.contents); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
	  totalPages.value = data.data.data.totalPages;
	}
	
	const dialPhoneNumber = (phoneNumber) => {
	    if (!phoneNumber) {
	      this.$toast({
	        message: "此用户未配置电话信息,请联系管理员",
	        position: "bottom",
	        duration: 2000
	      });
	      return;
	    }
	    window.location.href = "tel:" + phoneNumber;
	  }
	
	// const load = async () => {
	//   //滑到底部时进行加载
	//   console.log(123);
	//   loading.value = true;
	//   pages.value = pages.value + 10; //页数+10
	//   await onDefault(0, 1); //调用接口，此时页数+1，查询下一页数据
	// }
	
	const load = () => {
		if(loading.value == false) {
			setTimeout(() => {
			  //滑到底部时进行加载
			  //loading.value = true
			   onDefault(0, 1)
			}, 1000)
		}
	}
	
	//自动加载
	onMounted(() => {
	  fetchData()
	  beforeCreate()
	})
	
</script>



<style scoped>
	#clear {
		clear:both
	}
	#search {
		width: 98%;
		height: 40px;
		line-height: 40px;
		background: #fff;
		border-radius: 10px;
		text-align: center;
		margin-left: 1%;
		margin-top: 4%;
	}
	
	.el-row {
		width: 94%;
		margin-left: 3%;
	}
	
	.elInput{
		width: 100%;
		text-align: center;
		font-size: 20px;
	}
	input::-ms-input-placeholder{text-align: center;}
	input::-webkit-input-placeholder{text-align: center;}

	#Treatcontent #clear{
		width: 90%;
		list-style-type:none;
		min-height: 80px;
		line-height: 25px;
		background: #fff;
		border-radius: 10px;
		padding: 0px;
		margin-top: 10px;
	}
	#Treatcontent #clear .contentTitle {
		display: inline-block; 
		width: 96%;		
		font-size: 19px;
		color: #333333;
		padding-top: 10px;
		padding-left: 10px;
		padding-bottom: 10px;
	}
	#Treatcontent #clear .contentBrief {
		display: inline-block; 
		width: 90%;		
		font-size: 17px;
		color: #333333;
		padding-left: 10px;
		padding-bottom: 10px;
	}
	
	.contentIcon{
		width: 8%;
		display: inline-block;
		vertical-align: top;
	}
	.contentInfo{
		width: 92%;
		display: inline-block;
	}
	
	.loading {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}
</style>
