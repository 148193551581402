<template>
  <div class="swiper" :class="id">
    <div class="swiper-wrapper">
      <template v-for="(banner, idx) in data" :key="idx">
        <div class="swiper-slide">
          <img :src="banner.avatar" :style="{ width: '100%', height: '100%' }" />
        </div>
      </template>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper, { Autoplay, Navigation } from 'swiper'
Swiper.use([Autoplay, Navigation])
import 'swiper/swiper-bundle.min.css'
import { defineComponent, ref, onMounted, nextTick } from "vue";

export default defineComponent({
  name: "VSwiper",
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  setup() {
    const id = ref('v-swiper')
    const swiper = ref()
    onMounted(() => {
      nextTick(() => {
        swiper.value = new Swiper('.' + id.value, {
          // slidesPerView:auto,
          loop: true,
          autoplay: true,
          preventClicks: false,
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },

          observer: true,
          observeParents:true,
          on: {
            touchEnd: () => {
              console.log('update')
            }
          }
        })
      })
    })
    return { id, swiper }
  },
  watch: {
    swiper() {
      this.swiper.updateSlides();
    }
  }
})
</script>

<style scoped>
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 12px;
}
.swiper-slide img {
  height: 100%;
  display: block;
}
</style>
