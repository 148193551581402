<template>
		<el-affix >
			<div id="title"><span id="back" @click="onHome()" style="vertical-align: top;margin-top: -2px;">‹</span>{{ title }}</div>
		</el-affix>
		<div id="subTitle">{{contentsTitle}}</div>
		<div id="contents"  v-html="contentsValue">
		</div>
</template>

<script setup>
	import {defineComponent, onMounted, ref, watch, reactive} from 'vue'
	import * as api from "../api/loginHttp";
	import {ElMessage} from "element-plus";	
	import { Calendar, Search } from '@element-plus/icons-vue'
	import {useRoute, useRouter} from "vue-router"
	import http, {baseUrl} from "../utils/http";
	const route = useRoute()
	const router = useRouter()
	const cateid = ref()
	const title = ref()
	const id = ref()
	const contentsTitle = ref()
	const contentsValue = ref()
	const fetchData = async () => {
		cateid.value = route.params.cateid
		id.value = route.params.id
		http.get('api/news/getCateTitle/cateid' + "/" + cateid.value).then((res)=>{
			if(res.data.code == 200) {
				title.value = res.data.data
			} else {
				ElMessage.error('获取数据错误')
			}
		})
		http.get('api/news/getCustomerContents/id' + "/" + id.value).then((res)=>{
			if(res.data.code == 200) {
				contentsTitle.value = res.data.data.brief
				contentsValue.value = res.data.data.contents
			} else {
				ElMessage.error('获取数据错误')
			}
		
		})
	}
	const onHome = async () =>{
		await router.push({'path':'/Customer/'+ cateid.value})
	}
	//自动加载
	onMounted(() => {
	  fetchData()
	})
</script>

<style>
	#title {
		width: 100%;
		height: 45px;
		font-size: 20px;
		text-align: center;
		line-height: 45px;
		color: #4D4D4D;
		background-color: #fff;
		word-wrap:break-word;
	}

	#back {
		float: left;
		font-size: 30px;
	}
	#contents {
		background-color: #fff;
		word-wrap:break-word;
	}
	#contents img{
		width: 100%;
	}
</style>
