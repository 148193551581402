import http from '../utils/http.js'
//获取登录页默认值
export const getWebInfo = (parmas) => {
    return http
        .post("api/user/getWebInfo", parmas)
}
//用户登录
export const userLogin = (parmas) => {
    return http
        .post("api/user/login", parmas)
}
//获取用户权限
export const getAuth = (parmas) => {
    return http
        .post("api/index/getAuth", parmas)
}

export const resetPwd = (parmas) => {
    return http
        .post("api/user/resetpwd", parmas)
}

export const archives = (parmas) => {
    return http
        .post("api/archives/index", parmas)
}
// 新增文档
export const archivesAdd = (parmas) => {
    return http
        .post("api/archives/add", parmas)
}

// 编辑文档
export const archivesEdit= (parmas) => {
    return http
        .post("api/archives/edit", parmas)
}

// 获取当前用户菜品
export const getFood = (parmas) => {
    return http
        .post("api/getFood/index", parmas)
}

// 获取当前用户菜品
export const energy = (parmas) => {
    return http
        .post("api/archives/energy", parmas)
}

// 处理菜品
export const diet = (parmas) => {
    return http
        .post("api/archives/diet", parmas)
}
// 获取报告
export const report = (parmas) => {
    return http
        .post("api/archives/report", parmas)
}

export const evaluation = (parmas) => {
    return http
        .post("api/archives/evaluation", parmas)
}

export const setAdvice = (parmas) => {
    return http
        .post("api/archives/setAdvice", parmas)
}
export const getChart = (parmas) => {
    return http
        .post("api/archives/getChart", parmas)
}

export const getNewList = (parmas) => {
    return http
        .post("api/News/Newslist", parmas)
}

export const getCustomerCate = (parmas) => {
    return http
        .post("api/News/getCustomerCate", parmas)
}

export const getCustomerList = (parmas) => {
    return http
        .post("api/News/getCustomerList", parmas)
}
