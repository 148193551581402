import Home from "../views/Home.vue";

import Download from "../views/Download.vue";
import Business from "../views/Business.vue";
import Treatment from "../views/Treatment.vue";
import Contents from "../views/Contents.vue";
import Customer from "../views/Customer.vue";
import CustomerList from "../views/Customerlist.vue";
import CustomerDetail from "../views/Customerdetail.vue";
import onGetCustomer from "../views/onGetCustomer.vue"
import DefDemo from "../views/DefDemo.vue"
import HomeBig from "../views/Home-big.vue";
import BusinessBig from "../views/Business-big.vue";
import TreatmentBig from "../views/Treatment-big.vue";
import DownloadBig from "../views/Download-big.vue";
import ContentsBig from "../views/Contents-big";
import CustomerBig from "../views/Customer-big";
import CustomerListBig from "../views/Customerlist-big.vue";
import CustomerDetailBig from "../views/Customerdetail-big.vue";
// 快递模块
// import Express from '../views/express/Index.vue';
// import ExpressDelivery from '../views/express/Delivery.vue';

export default [
    { path: '/Home', component: Home },
	{ path: '/Download/:cateid', component: Download },
	{ path: '/Business/:cateid', component: Business },
	{ path: '/Treatment/:cateid', component: Treatment },
	{ path: '/Contents/:cateid/:id', component: Contents },
	{ path: '/Customer/:cateid/:child', component: Customer},
	{ path: '/CustomerList/:cateid/:type/:key', component: CustomerList },
	{ path: '/Customerdetail/:cateid/:type/:key/:id/:title', component: CustomerDetail },
	{ path: '/onGetCustomer/:cateid/:id', component: onGetCustomer},
	{ path: '/DefDemo', component: DefDemo},
	{ path: '/', redirect: '/Home' },
	// 寄快递
	// { path: '/Express', component: Express},
	// { path: '/ExpressDelivery', component: ExpressDelivery},
	
	//大字版本
	{ path: '/big/Home',component: HomeBig },
	{ path: '/big/Business/:cateid',component: BusinessBig },
	{ path: '/big/Treatment/:cateid',component: TreatmentBig },
	{ path: '/big/Download/:cateid',component: DownloadBig },
	{ path: '/big/Contents/:cateid/:id',component: ContentsBig },
	{ path: '/big/Customer/:cateid/:child',component: CustomerBig },
	{ path: '/big/CustomerList/:cateid/:type/:key',component: CustomerListBig },
	{ path: '/big/Customerdetail/:cateid/:type/:key/:id/:title',component: CustomerDetailBig },
]
