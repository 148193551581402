import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueCookies from 'vue-cookies'
import store from './store/index'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import infiniteScroll from 'vue-infinite-scroll'
import Common from './utils/common'
import VueTouch from 'vue-touch'
// import Mint from 'mint-ui';
import print from 'vue3-print-nb'

const app = createApp(App)
app.use(ElementPlus, VueCookies, store, locale, infiniteScroll, print, VueTouch, {name: 'v-touch'})
router(app)
Common(app)
app.mount('#app')